import React, { useState } from 'react';
import './tripPlanner.scss';
import 'leaflet/dist/leaflet.css';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import {
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  Add,
  Close,
  DirectionsCar,
  LocationOn,
  PanoramaFishEye,
  MoreVert,
  Print,
  SwapVert,
  List,
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { clearLocalStorage, DIRECTION_ICONS } from '../../lib/helper';

export default function TripPlanner({
  sourceCity,
  destinationCity,
  setSourceCity,
  setDestinationCity,
  setRouteDetails,
  onClose,
  routeDetails,
  destinations,
  setDestinations,
}) {
  const navigation = useNavigate();
  const [age, setAge] = React.useState('');
  const [destinationsMetadata, setDestinationsMetadata] = useState([]);
  const [selectSourcePlace, setSelectSourcePlace] = useState(
    (window?.localStorage &&
      JSON.parse(window?.localStorage.getItem('SOURCE_CITY_META_DATA'))) ||
      null
  );
  const [selectDestinationPlace, setSelectDestinationPlace] = useState(
    (window?.localStorage &&
      JSON.parse(window?.localStorage.getItem('DESTINATION_CITY_META_DATA'))) ||
      null
  );
  const [open, setOpen] = useState(false);

  const handleCollapse = () => {
    setOpen(!open);
  };

  const MAX_DESTINATIONS = 5;

  // Swap the source and destination places
  const handleSwap = () => {
    const temp = selectSourcePlace;
    setSelectSourcePlace(selectDestinationPlace); // Swap source to destination
    setSelectDestinationPlace(temp); // Swap destination to source

    const tempLocation = sourceCity;
    setSourceCity(destinationCity);
    setDestinationCity(tempLocation);
  };

  // Handle source place selection
  const handleSourcePlace = async (sourcePlace) => {
    try {
      if (window?.localStorage) {
        window?.localStorage?.setItem(
          'SOURCE_CITY_META_DATA',
          JSON.stringify(sourcePlace)
        );
      }

      setSelectSourcePlace(sourcePlace); // Store the entire sourcePlace object

      const results = await geocodeByAddress(sourcePlace.label);
      const latLng = await getLatLng(results[0]);
      setSourceCity({ ...latLng, name: sourcePlace.label });
    } catch (err) {
      console.error('handleSourcePlace ~ err:', err);
    }
  };

  // Handle destination place selection
  const handleDestinationPlace = async (destinationPlace) => {
    try {
      // STOR Locaton in local storage

      if (window?.localStorage) {
        window.localStorage.setItem(
          'DESTINATION_CITY_META_DATA',
          JSON.stringify(destinationPlace)
        );
      }
      setSelectDestinationPlace(destinationPlace); // Store the entire destinationPlace object

      const results = await geocodeByAddress(destinationPlace.label);
      const latLng = await getLatLng(results[0]);
      setDestinationCity({ ...latLng, name: destinationPlace.label });
    } catch (err) {
      console.error('handleDestinationPlace ~ err:', err);
    }
  };

  const handleRemoveDestination = (index) => {
    const newDestinationsMetadata = [...destinationsMetadata];

    newDestinationsMetadata.splice(index, 1); // Remove the destination at the specified index
    setDestinationsMetadata(newDestinationsMetadata);
    // Update localstorage
    if (window.localStorage) {
      window.localStorage.setItem(
        'DESTINATIONS_METADATA',
        JSON.stringify(newDestinationsMetadata)
      );
    }

    const newDestinations = [...destinations];
    newDestinations.splice(index, 1);

    setDestinations([...newDestinations]);
  };

  const addDestination = () => {
    if (destinationsMetadata.length < MAX_DESTINATIONS) {
      setDestinationsMetadata([...destinationsMetadata, { value: null }]);
    } else {
      toast.error('You cannot add more destinations.');
    }
  };

  // Handle dynamic destination place selection
  const handleAdditionalDestinationPlace = async (index, destinationPlace) => {
    const updatedDestinations = [...destinationsMetadata];
    updatedDestinations[index] = { value: destinationPlace };

    // Update localstorage
    if (window.localStorage) {
      window.localStorage.setItem(
        'DESTINATIONS_METADATA',
        JSON.stringify(updatedDestinations)
      );
    }

    if (
      destinations?.length !== 0 &&
      destinations?.length !== destinationsMetadata?.length &&
      destinationsMetadata?.length === 1
    ) {
      index += destinations?.length;
      setDestinationsMetadata([]);
    } else if (
      destinations?.length !== 0 &&
      destinations?.length !== destinationsMetadata?.length &&
      destinationsMetadata?.length === 0
    ) {
      setDestinationsMetadata([]);
    } else {
      index += destinations?.length;
      setDestinationsMetadata([]);
    }

    // Perform geocoding
    const results = await geocodeByAddress(destinationPlace.label);
    const latLng = await getLatLng(results[0]);

    // Update Destination logic

    if (!destinations[index]) {
      setDestinations((prev) => [
        ...prev,
        { ...latLng, name: destinationPlace.label },
      ]);
    }

    if (destinations[index]) {
      const newDestinations = [...destinations];
      newDestinations[index] = { ...latLng, name: destinationPlace.label };
      setDestinations([...newDestinations]);
    }
  };

  const handleClearTrip = () => {
    if (window?.localStorage) {
      clearLocalStorage();
    }
    setDestinationCity(null);
    setSourceCity(null);
    setSelectSourcePlace(null);
    setSelectDestinationPlace(null);
    setRouteDetails(null);
    setDestinations([]);
    setDestinationsMetadata([]);
  };

  const handlePrintClick = () => {
    navigation('/printable-routes');
  };

  const handleSelectChange = (event) => {
    setAge(event.target.value);
  };
  const handleBuildTrip = () => {};

  return (
    <Box>
      <Box className='wrapper'>
        <Box className='search-container'>
          <Box sx={{ display: 'flex', alignItems: 'start' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                width: '100%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                {/* Source Input */}
                <Box sx={{ display: 'flex', alignItems: 'end' }}>
                  <Box>
                    <PanoramaFishEye
                      sx={{ fontSize: '1.1rem', color: 'rgb(105, 105, 105)' }}
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <GooglePlacesAutocomplete
                      apiKey='AIzaSyDWSTyX3tb1R56qY1wYEkQrhUgyKmNjS8E'
                      selectProps={{
                        value: selectSourcePlace,
                        onChange: handleSourcePlace,
                        placeholder: sourceCity?.name
                          ? sourceCity?.name
                          : 'Start Point',
                        className: 'google-autocomplete',
                      }}
                    />
                  </Box>
                </Box>
                {destinations?.map((destination, index) => (
                  <Box
                    key={index}
                    sx={{ display: 'flex', alignItems: 'end', marginTop: 1 }}
                  >
                    <Box className='soourceIconWrap'>
                      <Box
                        className={`moreVertIconWrap moreVertIconWrap${
                          index + 1
                        }`}
                        sx={{ display: 'none' }}
                      >
                        <MoreVert
                          className='moreVertIcon'
                          sx={{ fontSize: '1.1rem' }}
                        />
                      </Box>
                      <PanoramaFishEye
                        className='panoramaFishIcon'
                        sx={{ fontSize: '1.1rem' }}
                      />
                      <Box className='moreVertIconWrap'>
                        <MoreVert
                          className='moreVertIcon'
                          sx={{ fontSize: '1.1rem' }}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <GooglePlacesAutocomplete
                        apiKey='AIzaSyDWSTyX3tb1R56qY1wYEkQrhUgyKmNjS8E'
                        selectProps={{
                          value: destination?.value,
                          onChange: (place) =>
                            handleAdditionalDestinationPlace(index, place),
                          placeholder: destinations[index]?.name
                            ? destinations[index]?.name
                            : `Additional Destination`,
                          className: 'google-autocomplete',
                        }}
                      />
                    </Box>
                    <Box sx={{ position: 'absolute', right: '50px' }}>
                      <IconButton
                        color='inherit'
                        onClick={() => handleRemoveDestination(index)}
                      >
                        <Close fontSize='small' />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
                {destinationsMetadata?.map((destination, index) => (
                  <Box
                    key={index}
                    sx={{ display: 'flex', alignItems: 'end', marginTop: 1 }}
                  >
                    <Box className='soourceIconWrap'>
                      <Box
                        className={`moreVertIconWrap moreVertIconWrap${
                          index + 1
                        }`}
                        sx={{ display: 'none' }}
                      >
                        <MoreVert
                          className='moreVertIcon'
                          sx={{ fontSize: '1.1rem' }}
                        />
                      </Box>
                      <PanoramaFishEye
                        className='panoramaFishIcon'
                        sx={{ fontSize: '1.1rem' }}
                      />
                      <Box className='moreVertIconWrap'>
                        <MoreVert
                          className='moreVertIcon'
                          sx={{ fontSize: '1.1rem' }}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <GooglePlacesAutocomplete
                        apiKey='AIzaSyDWSTyX3tb1R56qY1wYEkQrhUgyKmNjS8E'
                        selectProps={{
                          value: destination?.value,
                          onChange: (place) =>
                            handleAdditionalDestinationPlace(index, place),
                          placeholder: `Additional Destination`,
                          className: 'google-autocomplete',
                        }}
                      />
                    </Box>
                    <Box sx={{ position: 'absolute', right: '50px' }}>
                      <IconButton
                        color='inherit'
                        onClick={() => handleRemoveDestination(index)}
                      >
                        <Close fontSize='small' />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
                <Box
                  sx={{ display: 'flex', alignItems: 'end', marginTop: '8px' }}
                >
                  <Box>
                    <LocationOn fontSize='small' sx={{ color: '#F85B50' }} />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <GooglePlacesAutocomplete
                      apiKey='AIzaSyDWSTyX3tb1R56qY1wYEkQrhUgyKmNjS8E'
                      selectProps={{
                        value: selectDestinationPlace,
                        onChange: handleDestinationPlace,
                        placeholder: destinationCity?.name
                          ? destinationCity?.name
                          : 'End Point',
                        className: 'google-autocomplete',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <IconButton color='inherit' onClick={handleSwap}>
                  <SwapVert fontSize='small' />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <IconButton
                color='inherit'
                onClick={onClose}
                sx={{
                  borderLeft: '2px solid #ccc',
                  borderRadius: 0,
                  padding: 0,
                  paddingLeft: '5px',
                  marginLeft: '8px',
                }}
              >
                <Close fontSize='small' />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 1,
            }}
          >
            <Button
              variant='text'
              startIcon={<Add />}
              sx={{ color: 'black' }}
              className='trip-planner-btn'
              onClick={addDestination}
            >
              Add Destination
            </Button>
            <Button
              className='trip-planner-btn'
              variant='text'
              size='small'
              sx={{ color: 'black' }}
              onClick={handleBuildTrip}
            >
              Build Trip
            </Button>
            <Button
              className='trip-planner-btn'
              variant='text'
              size='small'
              sx={{ color: 'black' }}
              onClick={handleClearTrip}
            >
              Clear Trip
            </Button>
          </Box>
        </Box>
        {routeDetails?.length > 0 && (
          <>
            <Box className='rotuesDetail'>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DirectionsCar fontSize='small' />
                <Button
                  className='list-view-btn'
                  variant='text'
                  startIcon={<Print />}
                  sx={{ color: 'black' }}
                  onClick={handlePrintClick}
                >
                  Print
                </Button>
                <Typography className='duration'>
                  {routeDetails?.length > 0
                    ? `${parseInt(
                        parseInt(routeDetails[0]?.duration?.split(' '), 10) / 60
                      )}h:${parseInt(
                        parseInt(routeDetails[0]?.duration?.split(' '), 10) % 60
                      )}m`
                    : 'N/A'}
                </Typography>
                <Typography
                  className='distance'
                  sx={{ padding: '5px', color: '' }}
                >
                  {routeDetails[0]?.distance}
                </Typography>
              </Box>
              <Button
                className='list-view-btn'
                variant='text'
                startIcon={<List />}
                onClick={handleCollapse}
                sx={{ color: 'black' }}
              >
                List All Steps
              </Button>
            </Box>

            <Collapse in={open} timeout='auto' unmountOnExit>
              {/* <Box>
            <FormControl
              sx={{ margin: '10px 0', minWidth: 120, width: '100%' }}
              size='small'
            >
              <InputLabel id='demo-select-small-label'>Name</InputLabel>
              <Select
                labelId='demo-select-small-label'
                id='demo-select-small'
                value={age}
                label='Age'
                onChange={handleSelectChange}
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box> */}
              <Box
                sx={{
                  marginBottom: '10px',
                  padding: 2,
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  maxHeight: '180px',
                  overflowY: 'scroll',
                  marginTop: '10px',
                }}
              >
                {routeDetails?.length > 0 ? (
                  routeDetails[0]?.steps.map((step) => (
                    <div
                      className='routes-step-container'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '10px',
                      }}
                    >
                      {DIRECTION_ICONS[step?.directions]}
                      <p>{step.text}</p>
                      <p>Distance: {step.distance}</p>
                    </div>
                  ))
                ) : (
                  <p>Please select start and end point</p>
                )}
              </Box>
            </Collapse>
          </>
        )}
      </Box>
    </Box>
  );
}
