import {
  North,
  NorthEast,
  NorthWest,
  South,
  SouthEast,
  SouthWest,
  West,
  East,
} from '@mui/icons-material';

function extractValues(filters) {
  return Object.values(filters).flat();
}

export const DIRECTION_ICONS = {
  N: <North />,
  E: <East />,
  S: <South />,
  W: <West />,
  // Intercardinal directions:
  NE: <NorthEast />,
  NW: <NorthWest />,
  SE: <SouthEast />,
  SW: <SouthWest />,
  // Secondary intercardinal directions:
  // NNE: 'North - northeast',
  // NNW: 'North - northwest',
  // ENE: 'East - northeast',
  // ESE: 'East - southeast',
  // SSE: 'South - southeast',
  // SSW: 'South - southwest',
  // WSW: 'West - southwest',
  // WNW: 'West - northwest',
};

const KEYS_MAP = {
  CNG: 'StationFuelTypeCNG',
  LNG: 'StationFuelTypeLNG',
  'Vans and box trucks': 'VehicleTypesBoxTrucks',
  'Refuse and buses': 'VehicleTypesCarsAndVans',
  'Tractor-trailers': 'VehicleTypesSemiTrucks',
  Operational: 'StationStatus',
  'Under Maintenance': 'StationStatus',
  'Coming Soon': 'StationStatus',
  '24/7': 'HoursOpenIs24H',
  'Open Now': 'HoursOpenIs24H',
  Comdata: 'PaymentTypesAcceptedComData',
  'Fuelman Fleetwide': 'PaymentTypesAcceptedFuelmanFleetwide',
  'Mastercard Fleet': 'PaymentTypesAcceptedMasterCardFleet',
  TCH: 'PaymentTypesAcceptedTCH',
  'Visa Fleet': 'PaymentTypesAcceptedVisaFleet',
  Amex: 'PaymentTypesAcceptedAmex',
  Discover: 'PaymentTypesAcceptedDiscover',
  Tcheck: 'PaymentTypesAcceptedTcheck',
  'Clean Energy Fuel Card': 'PaymentTypesAcceptedCleanEnergyFuelCard',
  EFS: 'PaymentTypesAcceptedEFS',
  'Legacy EFS': 'PaymentTypesAcceptedLegacyEFS',
  'PFGift Card': 'PaymentTypesAcceptedPFGiftCard',
  Voyager: 'PaymentTypesAcceptedVoyager',
  Fleetone: 'PaymentTypesAcceptedWEXFleetone',
  'PFGIFT Card': 'PaymentTypesAcceptedPFGiftCard',
  Mastercard: 'PaymentTypesAcceptedMasterCard',
  Speedway: 'PaymentTypesAcceptedSpeedway',
  Visa: 'PaymentTypesAcceptedVisa',
};

const positiveValuesRef = {
  StationStatus: ['Active', 'Under Maintenance', 'Coming Soon'],
  Operational: 'Active',
  'Under Maintenance': 'Under Maintenance',
  'Coming Soon': 'Coming Soon',
  StationFuelTypeCNG: 'Yes',
  StationFuelTypeLNG: 'Yes',
  HoursOpen: 'Open 24/7;',
  HoursOpenIs24H: 'Yes',
  VehicleTypesCarsAndVans: 'Yes',
  VehicleTypesBoxTrucks: 'Yes',
  VehicleTypesSemiTrucks: 'Yes',
  PaymentTypesAcceptedCleanEnergyFuelCard: 'Yes',
  PaymentTypesAcceptedMasterCard: 'Yes',
  PaymentTypesAcceptedVisa: 'Yes',
  PaymentTypesAcceptedDiscover: 'Yes',
  PaymentTypesAcceptedVoyager: 'Yes',
  PaymentTypesAcceptedTranStar: 'Yes',
  PaymentTypesAcceptedNaturalFuels: 'Yes',
  PaymentTypesAcceptedAmex: 'Yes',
  PaymentTypesAcceptedComData: 'Yes',
  PaymentTypesAcceptedEFS: 'Yes',
  PaymentTypesAcceptedFuelmanFleetwide: 'Yes',
  PaymentTypesAcceptedLegacyEFS: 'Yes',
  PaymentTypesAcceptedMasterCardFleet: 'Yes',
  PaymentTypesAcceptedPFGiftCard: 'Yes',
  PaymentTypesAcceptedSpeedway: 'Yes',
  PaymentTypesAcceptedTCH: 'Yes',
  PaymentTypesAcceptedTcheck: 'Yes',
  PaymentTypesAcceptedVisaFleet: 'Yes',
  PaymentTypesAcceptedWEXFleetone: 'Yes',
};

export const filterServerData = (filters, targetArray) => {
  // Return early if no filters are selected
  if (!Object.keys(filters).length) {
    return targetArray;
  }

  const checkFilter = (station, filterKey, filterValues) => {
    return filterValues.every((value) => {
      const mappedKey = KEYS_MAP[value];
      if (!mappedKey) return false;

      // Custom logic for stationStatus filter
      if (filterKey === 'stationStatus') {
        return value === 'Operational'
          ? station[mappedKey] === 'Active' // Map 'Operational' to 'Active'
          : station[mappedKey] === value;
      }

      // Default logic: check for 'Yes' value
      return station[mappedKey] === 'Yes';
    });
  };

  const filterKeys = [
    'fuelType',
    'hours',
    'paymentTypes',
    'stationStatus',
    'vehicleSize',
  ];

  return targetArray.filter((station) => {
    // Apply AND operation across all filters
    return filterKeys.every((key) => {
      // Skip filter if it's not provided
      if (!filters[key]?.length) {
        return true;
      }

      // Check if station passes the filter for this key
      return checkFilter(station, key, filters[key]);
    });
  });
};

export const fetchLocationName = async (lat, lng) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=10&addressdetails=1`
    );
    const data = await response.json();
    if (data && data.address) {
      return data.display_name; // Get the readable location name
    }
    return `Un-Named Location with Lat: ${lat}, Lng: ${lng}`;
  } catch (error) {
    console.error('Error fetching location name:', error);
  }
};

export const clearLocalStorage = () => {
  window?.localStorage?.removeItem('SOURCE_CITY');
  window?.localStorage?.removeItem('DESTINATION_CITY');
  window?.localStorage?.removeItem('SOURCE_CITY_META_DATA');
  window?.localStorage?.removeItem('DESTINATION_CITY_META_DATA');
  window?.localStorage?.removeItem('DESTINATIONS_METADATA');
  window?.localStorage?.removeItem('DESTINATIONS');
};
